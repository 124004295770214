import React, { useEffect } from 'react';
import "../TestimonialPage.css";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import GrStar from '@meronex/icons/gr/GrStar';

function TestimonialPage() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    arrows: true,
    prevArrow: <div className="slick-arrow slick-prev">{'<'}</div>,
    nextArrow: <div className="slick-arrow slick-next">{'>'}</div>,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  const testimonialSliderData = [
    {
      id: 4,
      name:"Jennifer Glackin",
      quote:"Amazing experience with Huge Heifers Hauling. They were fairly priced, communicated so well, were prompt, quick & efficient!",
      time:"May 12, 2023"
    },
    {
      id: 5,
      name:"Joshua Nicholas",
      quote:"Hired Huge Heifers Hauling for a cardboard box removal job that had gotten out of control. Ethan communicated fantastically and was incredibly fair!",
      time:"May 12, 2023"
    },
    {
      id: 6,
      name:"Ham Zah",
      quote:"Had the guys at Huge Heifers Hauling come haul away some furniture and unwanted items from my business. They were very responsive to to my messages!",
      time:"April 10, 2023"
    },
    {
      id: 7,
      name: 'Evan Titus',
      quote: 'I texted Ethan and he responded right away and scheduled a time to come get everything. Sent me pictures to make sure it was satisfactory!',
      time: "July 7th, 2023"
    },
    {
      id: 8,
      name: 'Katie Vanslyke',
      quote: 'Huge Heifers Hauling did a fabulous job loading and hauling away our old fence in no time. They are professional, kind, and hard working!',
      time: "July 14th, 2023"
    },
    {
      id: 9,
      name: 'Brooklynn Brown',
      quote: 'Ethan, Asher, and Linkoln were prompt, professional, reasonable with pricing, and provided high quality service. They had our space clean in less than 30 minutes!',
      time: "July 20th, 2023"
    },
    {
      id: 10,
      name: 'Chris McGrath',
      quote: 'Ethan and Linkoln came out to haul away a mess that had piled up in our garage. They were finished in minutes and had my garage looking better than ever!',
      time:"July 21st, 2023"
    },
  ]
  // Sample data with before and after image URLs
  const testimonialGalleryData = [
    {
      id: 1,
      before: '/before1New.webp',
      after: '/after1New.webp',
      name: 'Evan Titus',
      quote: 'I texted Ethan and he responded right away and scheduled a time to come get everything. Sent me pictures to make sure it was satisfactory. Very happy with the way it looks and thankful to have it all cleared up! - Evan Titus',
    },
    {
      id: 2,
      before: '/before2New.webp',
      after: '/after2New.webp',
      name: 'Katie Vanslyke',
      quote: 'Huge Heifers Hauling did a fabulous job loading and hauling away our old fence in no time. They are professional, kind, and hard working! Would definitely recommend hiring them!\n- Katie Vanslyke',
    },
    {
      id: 3,
      before: '/before3New.webp',
      after: '/after3New.webp',
      name: 'Brooklynn Brown',
      quote: 'Ethan, Asher, and Linkoln were prompt, professional, reasonable with pricing, and provided high quality service. They had our space clean in less than 30 minutes! - Brooklynn Brown',
    },
    {
      id: 4,
      before: '/before4New.webp',
      after: '/after4New.webp',
      name: 'Chris McGrath',
      quote: 'Ethan and Linkoln came out to haul away a mess that had piled up in our garage. They were finished in minutes and had my garage looking better than ever!\n - Chris McGrath'
    },

    // Add more testimonial data here if needed
  ];



    useEffect(()=> {
        window.scrollTo(0,0);
    },[]);

  return (
    <div className="testimonial-page">
      <h1 className="testimonial-page__title">Our Work</h1>
      <p className="testimonial-page__description">Check out what our happy customers have to say:</p>
      <div className="testimonial-page__slider">
        <Slider {...settings}>
          {testimonialSliderData.map((testimonial) => (
            <div key={testimonial.id} className="testimonial-page__item">
              <p className="testimonial-page__rating"><GrStar/><GrStar/><GrStar/><GrStar/><GrStar/></p>
              <p className="testimonial-page__quote">{testimonial.quote}</p>
              <p className="testimonial-page__name">{testimonial.name}</p>
            </div>
          ))}
        </Slider>
      </div>
      <div className="testimonial-page__gallery">
        {testimonialGalleryData.map((testimonial) => (
            <>
          <div className="testimonial-item" key={testimonial.id}>
            <div className="testimonial-item__images-container">
              <img src={testimonial.before} alt="Before" className="testimonial-item__image" />
              <img src={testimonial.after} alt="After" className="testimonial-item__image" />
            </div>
            <p className="testimonial-item__quote">{testimonial.quote}</p>
          </div>
          </>
        ))}
      </div>
    </div>
  );
}

export default TestimonialPage;