import React, { useEffect } from 'react';

import "../LandingPage.css"

function LandingPage() {

    useEffect(()=> {
        window.scrollTo(0,0);
    },[]);

    return (
      <div className="landing-page">
        <header className="landing-page__header">
          <img alt='brand' style={{objectFit:"cover",width:"100%"}} src='/landingPageBrand.webp'></img>
        </header>
  
        <section className="landing-page__section">
          <div className="landing-page__image-container">
            <img src="/topShotNew.webp" alt="Junk Removal Services" className="landing-page__image" />
          </div>
          <div className="landing-page__text-container">
            <h2 className="landing-page__section-title">Fast and Reliable Junk Removal</h2>
            <p className="landing-page__section-description">
              We provide efficient and eco-friendly junk removal services for residential and commercial properties. Whether you need to declutter your home, clear out your office, or renovate a space, our professional team is here to help.
            </p>
            <p className="landing-page__section-description">
              With Huge Heifers Hauling, you can say goodbye to unwanted items and enjoy a clean, clutter-free environment. Our experienced team handles all the heavy lifting and ensures proper disposal, making the process easy and hassle-free for you.
            </p>
            <a href="/contact" className="landing-page__cta-button">Get a Free Quote</a>
          </div>
        </section>
  
        <section className="landing-page__section">
          <div className="landing-page__text-container">
            <h2 className="landing-page__section-title">Why Choose Us?</h2>
            <ul className="landing-page__features-list">
              <li>- Professional and friendly team</li>
              <li>- Quick and efficient service</li>
              <li>- Eco-friendly disposal</li>
              <li>- Competitive pricing</li>
              <li>- Flexible scheduling</li>
            </ul>
            <h5 style={{fontSize:"x-large"}} >But don't just take our word for it:</h5>
            <a href="/testimonials" className="landing-page__cta-button">Hear From Our Clients</a>
          </div>
          <div className="landing-page__image-container">
            <img src="/professionalShotNew.webp" alt="Professional Team" className="landing-page__image" />
          </div>
        </section>

        <section className="landing-page__section">
          <div className="landing-page__image-container">
            <img src="/actionShotNew.webp" alt="Junk Removal Services" className="landing-page__image" />
          </div>
          <div className="landing-page__text-container">
            <h2 className="landing-page__section-title">Clean is One Click Away</h2>
            <p className="landing-page__section-description">
              You've been wanting to tidy up your space, but haven't been able to find the time and you definitely don't want to pay a fortune for other commercial cleaning companies.
            </p>
            <p className="landing-page__section-description">
              You can call us right now and speak with a real person about taking care of any mess. We promise to offer great service at a fair price. That's the Triple H Guarantee.
            </p>
           <a href="tel:+13173739850" className="landing-page__cta-button">
            Call Us: (317) 373-9850
          </a>
          </div>
        </section>
      </div>
    );
  }
  
  export default LandingPage;