import React, {useState} from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import ContactPage from './components/ContactPage';
import TestimonialPage from './components/TestimonialPage';
import CgDetailsMore from '@meronex/icons/cg/CgDetailsMore';
import AiOutlineClose from '@meronex/icons/ai/AiOutlineClose';
import './App.css';

export function Layout({ children }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="layout">
      <header className="header">
        <div className="navbar__logo-container">
          <Link to="/" className="layout__logo-link">
            <img src="/logo.webp" alt="Logo" className="navbar__logo" />
          </Link>
        </div>
        <nav className={`navbar ${isMenuOpen ? 'open' : ''}`}>
          <ul className="navbar__list">
            <li className="navbar__item">
              <Link to="/" className="navbar__link" onClick={handleMenuToggle}>
                Home
              </Link>
            </li>
            <li className="navbar__item">
              <Link to="/testimonials" className="navbar__link" onClick={handleMenuToggle}>
                Our Work
              </Link>
            </li>
            <li className="navbar__item">
              <Link to="/contact" className="navbar__link" onClick={handleMenuToggle}>
                Contact
              </Link>
            </li>
          </ul>
        </nav>
        {isMenuOpen ? 
        <div className='mobile'>
       <ul className="navbar__list" style={{textAlign:"right",position:"fixed",right:"15%",top:"2%"}}>
            <li className="navbar__item">
              <Link to="/" className="navbar__link" onClick={handleMenuToggle}>
                Home
              </Link>
            </li>
            <li className="navbar__item">
              <Link to="/testimonials" className="navbar__link" onClick={handleMenuToggle}>
                Our Work
              </Link>
            </li>
            <li className="navbar__item">
              <Link to="/contact" className="navbar__link" onClick={handleMenuToggle}>
                Contact
              </Link>
            </li>
          </ul></div>  : null }
          <div className="mobile" style={{fontSize:"xx-large",position:'fixed',right:'3%',backgoundColor:"white"}}>
            {isMenuOpen ? <AiOutlineClose style={{color:"white"}} onClick={handleMenuToggle}/> : <CgDetailsMore style={{color:"white"}} onClick={handleMenuToggle}/>}
          </div>
      </header>
      <main className="main">{children}</main>
      <footer className="footer">
        <p>&copy; 2024 Huge Heifers Hauling. All rights reserved.</p>
      </footer>
    </div>
  );
}

function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<LandingPage/>} />
          <Route path="/testimonials" element={<TestimonialPage/>} />
          <Route path="/contact" element={<ContactPage/>} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;