import React, { useEffect, useRef, useState } from 'react';
import emailjs from '@emailjs/browser';


import "../ContactPage.css";

function ContactPage() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [serviceDescription, setServiceDescription] = useState('');
    const [preferredDate, setPreferredDate] = useState('');
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const form = useRef();

    useEffect(()=> {
        window.scrollTo(0,0);
    },[]);

    const handleSubmit = (e) => {
        e.preventDefault();
      
        // Validate form fields here
      
        // Submit the form or perform further actions
        let formObject = {
          name: name,
          email: email,
          phone: phone,
          serviceDescription: serviceDescription,
          preferredDate: preferredDate
        };
        console.log(JSON.stringify(formObject));
        emailjs
          .sendForm('service_dvhcky5', 'template_jlenbrs', form.current, 'jeZHirnFA8JoummNW')
          .then(
            (result) => {
              // Show the user a success message
              setSuccess(true);
              setError(false);
            },
            (error) => {
              // Show the user an error
              setSuccess(false);
              setError(true);
            }
          );
      };
  
      return (
        <div className="contact-page">
            <h1 className="contact-page__title">Contact Us</h1>
            {success && <><div className="contact-page__message success">Message sent successfully!</div><br></br></>}
            {error && <><div className="contact-page__message error">An error occurred. Please try again later.</div><br></br></>}
            <form ref={form} className="contact-page__form" onSubmit={handleSubmit}>
                <div className="contact-page__form-row">
                    <div className="contact-page__form-col">
                        <label htmlFor="name">Name:</label>
                        <input
                        type="text"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                        name="name"
                        />
                    </div>
                    <div className="contact-page__form-col">
                        <label htmlFor="email">Email:</label>
                        <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        name="email"
                        />
                    </div>
                </div>
                <div className="contact-page__form-row">
                    <div className="contact-page__form-col">
                        <label htmlFor="phone">Phone #:</label>
                        <input
                            type="tel"
                            id="phone"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            required
                            name="phone"
                        />
                    </div>
                    <div className="contact-page__form-col">
                        <label htmlFor="preferred-date">Preferred Date:</label>
                        <input
                            type="text"
                            id="preferred-date"
                            value={preferredDate}
                            onChange={(e) => setPreferredDate(e.target.value)}
                            required
                            name="preferredDate"
                            style={{paddingLeft:"20px"}}
                        />
                    </div>
                </div>
                <div className="contact-page__form-row">
                    <div className="contact-page__form-col">
                        <label htmlFor="service-description">Description of Service:</label>
                        <textarea
                        id="service-description"
                        value={serviceDescription}
                        onChange={(e) => setServiceDescription(e.target.value)}
                        required
                        name="serviceDescription"
                        style={{padding:"10px"}}
                        ></textarea>
                    </div>
                </div>
                <button type="submit" className="contact-page__submit-button">
                    Submit
                </button>
            </form>
        </div>
      );
  }
  
  export default ContactPage;